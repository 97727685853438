import React, { useCallback, useContext, useEffect } from 'react'
import styles from './selectorModal.module.scss'
import StandardModal from '../../../../../src/common/components/modal/standardModal/StandardModal'
import AppContext from '../../../../../src/common/context/context'
import { restore } from '../../../context/actions/selectorMotoActions'
import ContainerEditSelectorMoto from '../Common/ContainerEditSelectorMoto'
import { ModalErrorMedidas } from '../Common/modalErrorMedidas/modalErrorMedidas'

interface Props {
  showMarcas?: boolean
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void
  closeModal: () => void
  showModal: boolean
  title: string
}

let storedState = null

const SelectorModalMoto = ({
  handleSubmit,
  showMarcas,
  closeModal,
  showModal,
  title,
}: Props) => {
  const { dispatch, selectorMotoState } = useContext(AppContext)

  useEffect(() => {
    if (showModal) {
      storedState = selectorMotoState
    }
  }, [showModal])

  const onCloseModal = useCallback(() => {
    dispatch(restore(storedState))
    storedState = null
    closeModal()
  }, [closeModal])

  const onSubmit = (event) => {
    handleSubmit(event)
  }

  return (
    <StandardModal
      closeModal={onCloseModal}
      showModal={showModal}
      title={title}
      id="selector-modal">
      <section className={styles.sections}>
        <ContainerEditSelectorMoto
          showMarcas={showMarcas}
          handleSubmit={onSubmit}
        />
      </section>
      <ModalErrorMedidas />
    </StandardModal>
  )
}

export default SelectorModalMoto
